import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import Slider from 'react-slick'
import SEO from '../components/seo'

const Heading = styled.h1`
  margin-bottom: 0.25em;
`
const Subheading = styled.p`
  margin-bottom: 2em;
`
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`

const ServicesList = props => {
  let { slug, location, locationslug, stateAbbreviation, serviceTitle } = props
  return (
    <li className="text-base font-normal block">
      <Link
        to={
          '/' +
          locationslug +
          (stateAbbreviation ? `-${stateAbbreviation.toLowerCase()}` : '') +
          ('-' + slug)
        }
        title={serviceTitle + ' Company'}
      >
        {serviceTitle}
      </Link>
    </li>
  )
}

const CitiesServicesList = props => {
  let { location, stateAbbreviation, slug, services = [] } = props

  return (
    <li className="pb-4 text-lg font-semibold font-display leading-6 text-gray-800 flex-shrink-0 w-full sm:w-1/3 md:w-1/4">
      <p className="block">
        {location}, {stateAbbreviation}
      </p>
      <div className="py-1">
        <ul>
          {services.edges.map((item, i) => {
            let cleanReplaceServiceTitle = item.node.serviceTitle.replace(
              /{{city}}/g,
              location
            )
            return (
              <ServicesList
                key={item.node.id}
                locationslug={slug}
                location={location}
                serviceTitle={cleanReplaceServiceTitle}
                stateAbbreviation={stateAbbreviation}
                slug={item.node.slug}
              />
            )
          })}
        </ul>
      </div>
    </li>
  )
}

const ServiceArea = props => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              stateAbbreviation
              slug
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout pageProps={props} location={props.location}>
          <SEO
            title={'Service Area | WebPerfex'}
            description={'This page contains a list of our service areas.'}
          />

          <section className="w-full px-4 md:px-6 py-6 container mx-auto">
            <h1 className="md:text-4xl font-semibold leading-10 text-3xl">
              Service Area
            </h1>
            <p className="text-gray-700 leading-6">
              WebPerfex is proud to provide exceptional web design and
              marketing services to the following areas. If your city is not
              listed below, please contact us and we will be happy to assist.
            </p>
            <div className="py-4 mt-6">
              <ul className="mt-2 leading-relaxed p-0 list-none flex flex-wrap">
                {data.allContentfulServiceArea.edges.map((item, i) => {
                  return (
                    <CitiesServicesList
                      key={item.node.id}
                      location={item.node.location}
                      stateAbbreviation={item.node.stateAbbreviation}
                      slug={item.node.slug}
                      services={data.allContentfulServiceAreaContent}
                    />
                  )
                })}
              </ul>
            </div>
          </section>
        </Layout>
      )
    }}
  />
)

export default ServiceArea
